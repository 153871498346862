<template>
  <el-main class="datause-container">
    <div class="inner-container">
      <div class="section-h2">
        <MainContain />
        【公告】運動數據平台「體適能數據格式」改善更新，8/15正式實施
      </div>
      <div class="content-desc">
        公告日期：2024-08-01
      </div>
      <div class="content-section">
        <section class="content-section">
          <div class="content-desc">
            親愛的平台用戶：
          </div>
          <div class="content-desc">
            為了進一步提升我們平台的數據品質和使用效率，我們將於 [2024-08-15] 實施一系列數據格式優化。這些優化主要針對體適能相關的 24 個數據類型，包括成人體適能、高齡體適能和專項體能測試。
          </div>
        </section>
        <section class="content-section">
          <div class="content-desc">
            量測項目精簡：依據2023年底專家提出之修正建議，將體適能相關部分子類型之量測項目精簡，以聚焦於最關鍵的數據。
          </div>
          <ol>
            <li>數據一致性提升：通過統一必填項目，我們可以確保跨不同測試類型的數據一致性。</li>
            <li>數據收集靈活性：簡化的可選項目為研究者和使用者提供了更多的數據收集選項。</li>
            <li>分析能力增強：擴充的基本資料欄位將支持更深入、更全面的數據分析。</li>
          </ol>
        </section>
        <section class="content-section">
          <div class="section-h2">
            變更對照表
          </div>
          <div class="content-desc">
            注意事項：
          </div>
          <ol>
            <li>必填項目在修正前後保持不變。</li>
            <li>所有數據類型的基本資料欄位都從原本的 12 個擴充到 14 個 (「身高」與「體重」)，以提供更全面的背景資訊。</li>
            <li>表格中的「-」表示該項目在修正後沒有選填項目。</li>
          </ol>
          <div class="section-h3">
            成人體適能
          </div>
          <table class="update-table">
            <thead>
              <tr>
                <th>序號</th>
                <th>子類型</th>
                <th>必填項目（不變）</th>
                <th>修正後選填項目</th>
                <th>修正內容</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>登階測驗</td>
                <td>心肺耐力指數</td>
                <td>達成次數, 下肢肌力, 上肢肌力, 前彎柔軟度, 距離(公尺), 花費時間</td>
                <td>簡化選填項目，保留與運動表現直接相關的指標</td>
              </tr>
              <tr>
                <td>2</td>
                <td>十二分鐘跑走</td>
                <td>距離(公尺)</td>
                <td>達成次數, 下肢肌力, 上肢肌力, 前彎柔軟度, 心肺耐力指數, 花費時間</td>
                <td>簡化選填項目，保留與運動表現直接相關的指標</td>
              </tr>
              <tr>
                <td>3</td>
                <td>伏地挺身</td>
                <td>達成次數</td>
                <td>花費時間, 最大心率, 消耗卡路里, 平均心率</td>
                <td>簡化選填項目，保留與運動表現直接相關的指標</td>
              </tr>
              <tr>
                <td>4</td>
                <td>一分鐘屈膝仰臥起坐</td>
                <td>達成次數</td>
                <td>花費時間</td>
                <td>大幅簡化選填項目，僅保留「花費時間」</td>
              </tr>
              <tr>
                <td>5</td>
                <td>坐姿體前彎</td>
                <td>前彎柔軟度</td>
                <td>-</td>
                <td>移除所有選填項目，僅保留必填項</td>
              </tr>
              <tr>
                <td>6</td>
                <td>立姿體前彎</td>
                <td>前彎柔軟度</td>
                <td>-</td>
                <td>移除所有選填項目，僅保留必填項</td>
              </tr>
              <tr>
                <td>7</td>
                <td>左手握力</td>
                <td>上肢肌力</td>
                <td>-</td>
                <td>移除所有選填項目，僅保留必填項</td>
              </tr>
              <tr>
                <td>8</td>
                <td>右手握力</td>
                <td>上肢肌力</td>
                <td>-</td>
                <td>移除所有選填項目，僅保留必填項</td>
              </tr>
              <tr>
                <td>9</td>
                <td>反向運動跳躍CMJ</td>
                <td>跳躍高度</td>
                <td>-</td>
                <td>移除所有選填項目，僅保留必填項</td>
              </tr>
              <tr>
                <td>10</td>
                <td>505敏捷測試</td>
                <td>花費時間</td>
                <td>-</td>
                <td>移除所有選填項目，僅保留必填項</td>
              </tr>
              <tr>
                <td>11</td>
                <td>等長大腿中段拉IMTP</td>
                <td>下肢肌力</td>
                <td>-</td>
                <td>移除所有選填項目，僅保留必填項</td>
              </tr>
              <tr>
                <td>12</td>
                <td>閉眼單腳站立平衡(左腳)</td>
                <td>花費時間</td>
                <td>-</td>
                <td>移除所有選填項目，僅保留必填項</td>
              </tr>
              <tr>
                <td>13</td>
                <td>閉眼單腳站立平衡(右腳)</td>
                <td>花費時間</td>
                <td>-</td>
                <td>移除所有選填項目，僅保留必填項</td>
              </tr>
            </tbody>
          </table>
          <div class="section-h3">
            高齡體適能
          </div>
          <table class="update-table">
            <thead>
              <tr>
                <th>序號</th>
                <th>子類型</th>
                <th>必填項目（不變）</th>
                <th>修正後選填項目</th>
                <th>修正內容</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>8英呎起身繞行</td>
                <td>花費時間</td>
                <td>-</td>
                <td>移除所有選填項目，僅保留必填項</td>
              </tr>
              <tr>
                <td>2</td>
                <td>30秒單腳平衡站立(左腳)</td>
                <td>花費時間</td>
                <td>-</td>
                <td>移除所有選填項目，僅保留必填項</td>
              </tr>
              <tr>
                <td>3</td>
                <td>30秒單腳平衡站立(右腳)</td>
                <td>花費時間</td>
                <td>-</td>
                <td>移除所有選填項目，僅保留必填項</td>
              </tr>
              <tr>
                <td>4</td>
                <td>30秒椅子坐站</td>
                <td>達成次數</td>
                <td>花費時間</td>
                <td>簡化選填項目，僅保留「花費時間」</td>
              </tr>
              <tr>
                <td>5</td>
                <td>2分鐘原地抬膝踏步</td>
                <td>達成次數</td>
                <td>花費時間</td>
                <td>簡化選填項目，僅保留「花費時間」</td>
              </tr>
              <tr>
                <td>6</td>
                <td>30秒肱二頭肌手臂屈舉</td>
                <td>達成次數</td>
                <td>花費時間</td>
                <td>簡化選填項目，僅保留「花費時間」</td>
              </tr>
              <tr>
                <td>7</td>
                <td>4公尺行走速度</td>
                <td>花費時間</td>
                <td>-</td>
                <td>移除所有選填項目，僅保留必填項</td>
              </tr>
              <tr>
                <td>8</td>
                <td>4公尺行走速度(使用輔具)</td>
                <td>花費時間</td>
                <td>-</td>
                <td>移除所有選填項目，僅保留必填項</td>
              </tr>
              <tr>
                <td>9</td>
                <td>左手握力</td>
                <td>上肢肌力</td>
                <td>-</td>
                <td>移除所有選填項目，僅保留必填項</td>
              </tr>
              <tr>
                <td>10</td>
                <td>右手握力</td>
                <td>上肢肌力</td>
                <td>-</td>
                <td>移除所有選填項目，僅保留必填項</td>
              </tr>
            </tbody>
          </table>
          <div class="section-h3">
            專項體能
          </div>
          <table class="update-table">
            <thead>
              <tr>
                <th>序號</th>
                <th>子類型</th>
                <th>必填項目（不變）</th>
                <th>修正後選填項目</th>
                <th>修正內容</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>米字步敏捷性測試</td>
                <td>達成次數</td>
                <td>花費時間</td>
                <td>簡化選填項目，僅保留「花費時間」</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section class="content-section">
          <div class="section-h2">
            注意事項
          </div>
          <ol>
            <li>請確保在實施日期後，所有新上傳的數據都符合新的格式要求。</li>
            <li>歷史數據不會受到影響，但在進行跨時期的數據分析時需要注意格式差異。</li>
            <li>如果您使用 API 進行數據上傳，請務必更新您的程式碼以符合新的數據結構。</li>
          </ol>
        </section>
        <section class="content-section">
          <div class="section-h2">
            支援與協助
          </div>
          <div class="content-desc">
            如果您在適應新格式過程中遇到任何問題，或需要進一步的說明，請隨時聯繫我們的技術支援團隊：
          </div>
          <div class="content-desc">
            我們將竭誠為您提供協助，確保您能順利過渡到新的數據格式。
          </div>
          <div class="content-desc">
            感謝您一如既往的支持和合作。
          </div>
          <div class="content-desc">
            運動數據公益平台維運團隊 敬啟 2024年7月29日
          </div>
        </section>
      </div>
    </div>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'Updates02FitnessData',
  components: {
    MainContain
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.datause-container {
  color: $text_dark;
  font-size: 1rem;
  .inner-container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .content-section{
    padding: 0.625rem;
    .content-desc{
      line-height: 1.5;
      padding: 0.625rem 0;
    }
  }
  li{
    line-height: 1.5;
  }
  .update-table {
            border-collapse: collapse;
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
        }
        .update-table th, .update-table td {
            border: 1px solid black;
            padding: 8px;
        }
        ul {
            padding-left: 20px;
        }
}
</style>
